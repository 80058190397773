<template>
  <div class="w-100">
    <div class="ml-3">
      <img src="@/static/image/load-logo.png">
    </div>
    <div class="text-align m-auto w-100">
      <div class="d-flex align-items-center justify-content-center">
        <div class="position-relative">
          <h2 style="font-size:3.0rem">兴业银行深圳分行智能报告系统V1.0</h2>
          <span v-if="content.version" class="version px-3 border rounded-lg border-dark py-0 position-absolute " style="top:0;right:-70px;">{{content.version}}</span>
        </div>
      </div>
      <div class=" d-flex justify-content-center">
        <div class='hr'></div>
      </div>
      <div class="d-flex align-items-center justify-content-center mt-2">
        <h5 class='d-flex align-items-center px-3 font-s'>
          <span class="dot"></span> 测试版
        </h5>
<!--        <h5 class='d-flex align-items-center px-3'>-->
<!--          <span class="dot"></span> 校验报告下载-->
<!--        </h5>-->
<!--        <h5 class='d-flex align-items-center px-3'>-->
<!--          <span class="dot"></span> 评测数据导出-->
<!--        </h5>-->
      </div>
      <div class="d-flex align-items-center justify-content-center " style=" margin-top: 60px;">
        <a v-if="content.url" :href='content.url' class="load px-3 d-flex align-items-center py-2  rounded-lg ">
          <img src='@/static/image/load.png'>
          <div class='load-txt d-flex align-items-center'>
            立即下载 <span class="pl-1" style="font-size:18px"> ({{content.size}})</span>
          </div>
        </a>
        <div v-else class="load px-3 d-flex align-items-center py-2  rounded-lg " style="cursor: pointer" data-toggle="modal" data-target="#staticBackdrop">
          <img src='@/static/image/load.png'>
          <div class='load-txt d-flex align-items-center'>
            立即下载 <span class="pl-1" style="font-size:18px"></span>
          </div>
        </div>
      </div>

      <h4 class="d-flex align-items-center  flex-column justify-content-center  mt-2 ">
        <div class='load-txt-user pt-1'> *适用于Windows用户下载</div>
      </h4>
      <div>
        <img class='w-100' :src="require('@/static/image/download-xingye-bank.png')">
      </div>
    </div>
    <div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">下载</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="exampleFormControlInput1">密码</label>
              <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="请输入下载密码" v-model="password" @blur="onPwdBlur">
              <div v-if="showTips" class="tip">请输入密码</div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-cancel" data-dismiss="modal">取消</button>
            <button type="button" class="btn btn-submit" :data-dismiss="modal" @click="onSubmit">提交</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import baseUrl from "@/utils/request.js";

  export default {
    name: "DownloadFces",
    data() {
      return {
        content: {
          softName: '',
          version: '',
          url: '',
          size: ''

        },
        showTips:false,
        password:"",
        modal: ""
      }
    },
    mounted() {
      // this.getLoadInfo();
    },
    methods: {
      async onSubmit(){
        let str = this.getPassword()
        console.log(str)
        if(this.password === str){
          this.modal="modal"
          await this.getLoadInfo()
          this.downloadFile()
        }
      },

      getPassword(){
        let date = new Date();
        console.log(date.getFullYear(),date.getMonth(),date.getDate());
        let year = date.getFullYear() + ""
        let month = (date.getMonth()+1 +"").padStart(2,'0')
        let day = (date.getDate()+"").padStart(2,'0')
        let str = (month + day)
        let result = ""
        for(let i=0;i< year.length;i++){
          result= result + year[i]+str[i]
        }
        let arr = result.split("")
        arr.splice(date.getDay()-1,0, date.getDay()+'')
        return arr.join("")
      },

      downloadFile(){
        let element = document.createElement("a");
        element.setAttribute("download", `兴业银行深圳分行智能报告系统-${this.content.version}-setup.exe`);
        element.setAttribute("href", this.content.url);
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      },

      onPwdBlur(){
        console.log(this.password)
        this.showTips = !this.password
      },
      async getLoadInfo() {
        /*
       解决跨域：
         1 打开代理 ==> vue.config.js
         2 ${baseUrl.BASE_URL}改为api
         .get(`api/system/app/latest-version?eplatformTypes=WINDOWS`)
       */
        try {
          let res = await this.$axios.get(`${baseUrl.BASE_URL}/system/app/latest-version?platformType=windows&softCode=xingye-bank`)
          this.$loading.show();
          let list = res.data.data;
          this.content = list.find(item => {
            return item.platformType === 'windows'
          })
          this.content.size = this.formatFileUnit(this.content.size)
          console.log("软件信息成功", this.content)
          this.$loading.hide()
        }catch (e) {
            this.$loading.hide()
            console.log("软件信息失败", error);
        }
      },
      formatFileUnit(limit) {
        let size = "";
        if (limit < 1024) { //如果小于0.1KB转化成B
          size = limit.toFixed(2) + "B";
        } else if (limit < 1024 * 1024) {//如果小于0.1MB转化成KB
          size = (limit / 1024).toFixed(2) + "KB";
        } else if (limit < 1024 * 1024 * 1024) { //如果小于0.1GB转化成MB
          size = (limit / (1024 * 1024)).toFixed(2) + "MB";
        } else { //其他转化成GB
          size = (limit / (1024 * 1024 * 1024)).toFixed(2) + "GB";
        }

        let sizestr = size + "";
        let len = sizestr.indexOf(".");
        let dec = sizestr.substr(len + 1, 2);
        if (dec === "00") {//当小数点后为00时 去掉小数部分
          return sizestr.substring(0, len) + sizestr.substr(len + 3, 2);
        }
        return sizestr;
      },

    }
  }
</script>

<style>
  .dot {
    display: inline-block;
    width: 5px;
    height: 5px;
    background: #333;
    border-radius: 100%;
    margin-right: 5px;
  }

  .load {
    background: #f65b51;
  }

  .load img {
    width: 30px;
    height: 30px;
  }

  .load .load-txt {
    font-size: 22px;
    color: white;
    padding-left: 10px;
  }

  .load-txt-user {
    color: #f65b51;
    font-size: 18px;
  }

  .hr {
    width: 35%;
    height: 1px;
    background: #ddd;
    margin: 30px 0px;
  }

  body {
    background: #fAfAfA;
  }

  .tag {
    background: linear-gradient(90deg, #F29A53, #EC514A);
    color: white;
    top: calc(-100%);
    right: 0;
    padding: 5px 10px;
    font-size: 12px;
    box-sizing: border-box;
    border-radius: 50px 50px 50px 0;
  }

  .btn-cancel{
    width: 80px;
    border: solid 1px #eeeeee;
  }

  .btn-cancel:hover{
    opacity: 0.8;
  }

  .btn-submit{
    width: 80px;
    background: #f65b51;
    color:white;
  }
  .btn-submit:hover{
    color: white;
    opacity: 0.8;
  }
  .tip{
    font-size: 12px;
    color: #f65b51;
    margin-top: 4px;
  }
  .form-control:focus {
    border-color: #f65b51;
    box-shadow: 0 0 0 0.2rem rgba(236, 81, 74, 0.25);
  }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(236, 81, 74, 0.25);
  }
</style>
