<template>
  <div ref="content" style="overflow-y: auto;height: 100vh">
    <download-pve style="min-height: 100vh" />
    <download-pvmde style="min-height: 100vh" />
    <download-fces style="min-height: 100vh" />
    <download-industrial-bank style="min-height: 100vh" />
  </div>
</template>
<script>
  import DownloadPve from "@/components/download/DownloadPve";
  import DownloadPvmde from "@/components/download/DownloadPvmde";
  import DownloadFces from "@/components/download/DownloadFces";
  import DownloadIndustrialBank from "@/components/download/DownloadIndustrialBank";

  export default {
    name: "downloadCenter",
    components: { DownloadFces, DownloadPvmde, DownloadPve, DownloadIndustrialBank },
    data() {
      return {
        heightArr: null,
        lastScrollTop: 0,
        isScrolling: false //是否正在滚动
      }
    },

    mounted() {
      if (this.$route.params.version === "fces1.0") {
        this.onScroll(2)
      } else if (this.$route.params.version === "pvmde1.0") {
        setTimeout(() => {
          this.onScroll(1)
        }, 50)
      } else if (this.$route.params.version === "pve1.0") {

      } else {

      }
    },

    computed: {},

    methods: {
      onScroll(index) {
        // let currentScrollTop = this.$refs.content.scrollTop;
        let scrollTop = this.$refs.content.children[0].getBoundingClientRect().height * index;
        try {
          this.$refs.content.scrollTo({
            behavior: "smooth",
            top: scrollTop
          })
        } catch (e) { //ie11
          console.log(e);
          this.$refs.content.scrollTop = scrollTop
        }
      }
    },
  }
</script>
<style lang="less" scoped>

</style>
