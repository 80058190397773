<template>
  <div class="w-100">
    <div class="ml-3">
      <img src="@/static/image/load-logo.png">
    </div>
    <div class="text-align m-auto w-100">
      <div class="d-flex align-items-center justify-content-center">
        <div class="position-relative">
          <h2 style="font-size:3.0rem">中壬银兴PVE评估系统</h2>
          <span class="version px-3 border rounded-lg border-dark py-0 position-absolute " style="top:0;right:-70px;">{{content.version}}</span>
        </div>
      </div>
      <div class=" d-flex justify-content-center">
        <div class='hr'></div>
      </div>
      <div class="d-flex align-items-center justify-content-center mt-2">
        <h5 class='d-flex align-items-center px-3 font-s'>
          <span class="dot"></span> 财务数据核算校验
        </h5>
        <h5 class='d-flex align-items-center px-3'>
          <span class="dot"></span>流水关联交易校验
        </h5>
        <h5 class='d-flex align-items-center px-3'>
          <span class="dot"></span>财务数据核算校验
        </h5>
      </div>
      <div class="d-flex align-items-center justify-content-center " style=" margin-top: 60px;">
        <a :href='content.url' class="load px-3 d-flex align-items-center py-2  rounded-lg ">
          <img src='@/static/image/load.png'>
          <div class='load-txt d-flex align-items-center'>
            立即下载 <span class="pl-1" style="font-size:18px"> ({{content.size}})</span>
          </div>
        </a>
      </div>

      <h4 class="d-flex align-items-center  flex-column justify-content-center  mt-2 ">
        <div class='load-txt-user pt-1'> *适用于Windows用户下载</div>
      </h4>
      <div>
        <img class='w-100' :src="require('@/static/image/download-pve.png')">
      </div>
    </div>
  </div>
</template>

<script>
  import baseUrl from "@/utils/request.js";

  export default {
    name: "Download",
    data() {
      return {
        content: {
          softName: '',
          version: '',
          url: '',
          size: ''

        },
      }
    },
    mounted() {
      this.getLoadInfo();
    },
    methods: {
      getLoadInfo() {
        /*
       解决跨域：
         1 打开代理 ==> vue.config.js
         2 ${baseUrl.BASE_URL}改为api
         .get(`api/system/app/latest-version?platformTypes=WINDOWS`)
       */
        this.$axios
          .get(`${baseUrl.BASE_URL}/system/app/latest-version?platformType=windows&softCode=pve`)
          .then((res) => {
            this.$loading.show();
            let list = res.data.data;
            this.content = list.find(item => {
              return item.platformType === 'windows'
            })
            this.content.size = this.formatFileUnit(this.content.size)
            console.log("软件信息成功", this.content)
            this.$loading.hide()
          })
          .catch((error) => {
            this.$loading.hide()
            console.log("软件信息失败", error);
          });
      },
      formatFileUnit(limit) {
        let size = "";
        if (limit < 1024) { //如果小于0.1KB转化成B
          size = limit.toFixed(2) + "B";
        } else if (limit < 1024 * 1024) {//如果小于0.1MB转化成KB
          size = (limit / 1024).toFixed(2) + "KB";
        } else if (limit < 1024 * 1024 * 1024) { //如果小于0.1GB转化成MB
          size = (limit / (1024 * 1024)).toFixed(2) + "MB";
        } else { //其他转化成GB
          size = (limit / (1024 * 1024 * 1024)).toFixed(2) + "GB";
        }

        let sizestr = size + "";
        let len = sizestr.indexOf(".");
        let dec = sizestr.substr(len + 1, 2);
        if (dec === "00") {//当小数点后为00时 去掉小数部分
          return sizestr.substring(0, len) + sizestr.substr(len + 3, 2);
        }
        return sizestr;
      },

    }
  }
</script>

<style>
  .dot {
    display: inline-block;
    width: 5px;
    height: 5px;
    background: #333;
    border-radius: 100%;
    margin-right: 5px;
  }

  .load {
    background: #f65b51;
  }

  .load img {
    width: 30px;
    height: 30px;
  }

  .load .load-txt {
    font-size: 22px;
    color: white;
    padding-left: 10px;
  }

  .load-txt-user {
    color: #f65b51;
    font-size: 18px;
  }

  .hr {
    width: 35%;
    height: 1px;
    background: #ddd;
    margin: 30px 0px;
  }

  body {
    background: #fAfAfA;
  }
</style>
